import React, { useEffect, useState, useRef  } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Box, Toolbar, IconButton,
  Menu, MenuItem, Badge, Button,
  FormControl, Select, Typography, CircularProgress, Grid, Table, TableBody, TableContainer, TableFooter,
  TablePagination, TableRow, Paper, TableHead
} from '@material-ui/core';
import { useStyles, StyledTableCell, StyledTableRow } from './styles'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { getHelpContent, getFaqContent, getEscrowTransaction } from '../../../actions';
import { getUserType, getAppSource, numberWithCommas} from '../../../helpers';
import { HOW_IT_WORKS_MORTGAGE_TAX } from '../../../helpers/constants';

import { useHistory } from 'react-router-dom';
import  {Faq} from './../Faq';
import moment from 'moment';
import './index.css';
import {CoveredTile} from './../CoveredTile';
import { CoveredTileP2S } from './../CoveredTileP2S';
import Cookies from 'js-cookie';

export const MortgageIns = (props) => {

  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const { accountNumber } = props;
  const privateLabelUrl = Cookies.get("privateLabelUrl")

  const classes = useStyles();
  const history = useHistory();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [data, setFaqData] = useState()
  const [loading, setLoading] = useState(false)
  const [escrowDetails, setEscrowDetails] = useState([]);

  const [list, setMortgageLists] = useState([])

  useEffect(() => {
    if (accountNumber != '') {
      getFaqData();
      getTransactionHistoryData()
     if (props?.mortgageInsurances != null) {
      setMortgageInsurances()
     }
    }
  },[accountNumber]);
  
  const setMortgageInsurances = () => {
    const data = props?.mortgageInsurances;

     const mi =  [{
        "payeeName": data.payeeName,
        "policyTaxId": data.policyTaxId,
        "principalBalanceAmount": data.principalBalanceAmount,
        "taxIdType": data.taxIdType,
        "miMonthlyPremiumAmount": data.miMonthlyPremiumAmount,
        "originalAppraisedAmount": data.originalAppraisedAmount,
        "originalLoanAmount": data.originalLoanAmount,
        "status": true
      }]
      
      setMortgageLists(mi)
  }

  const getFaqData = async () => {
   let a = await dispatch(getFaqContent('PC029'));
   setFaqData(a)
  }
 
  const handleViewMore = () => {
    history.push({pathname: '/TransactionHistory' , search: 'escrow'});
  }

  const setStatusValue = (index, status) => {
    let newObj = [...list]  
    newObj[index]['status'] = !status
    setMortgageLists(newObj)
  }

  const getTransactionHistoryData = async () => {
    setLoading(true)
    const res = await getEscrowTransaction(props?.accountNumber);
    if (res) {
     let filterData = res.lstEscrowDetails.filter((item) => (item.escrowType == ('PMI' || 'MI')))
      setEscrowDetails(filterData)
    }
    setLoading(false)
  }


  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root} >
          <Box className={classes.formContainerAccordian} ref={componentRef}>
            <Box className={classes.taxformContainer1}>
              <Typography className={classes.howItWork}>How It Works</Typography>
                <Typography className={classes.howItWorkDesc}>
                 {HOW_IT_WORKS_MORTGAGE_TAX}
                </Typography>
                <Grid container>
              <Grid item md={12} style={{ paddingRight: '20px'}} xs={12} lg={12}>
            
              <Box  style={{ paddingTop: '26px',  paddingBottom: '60px'}}>
            
            {list.map((key, index) => {  
              return(
                <Box  className={`${classes.accordian} ${'acc1'}`}>
               <Accordion elevation={0} expanded={key.status == true} onChange={() => setStatusValue(index, key.status)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon  className={classes.expand_icon}/>}
                aria-controls={`panel${index}c-content`}
                id={`panel${index}c-header`}
                className={classes.transactionText}
                style={{borderLeft: `6px solid ${primaryColorCode}`}}
                >
                 <Box>
                  {key.payeeName} - {key.taxIdType} {key.policyTaxId ? key.policyTaxId : 'N/A'}
                </Box>
              </AccordionSummary>
              <AccordionDetails >
              <Box  className={classes.accDetails}>
                <Grid item md={6} xs={12} lg={12}> 
                <Box>
                  <Grid container>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography className={classes.boxText}>Original Loan Amount:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}>
                      {   Math.sign(key?.originalLoanAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(key?.originalLoanAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(key?.originalLoanAmount).toFixed(2)) }
                        </Typography>
                      </Grid>
                  
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxText}>Current Loan Amount:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}> 
                      {   Math.sign(key?.principalBalanceAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(key?.principalBalanceAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(key?.principalBalanceAmount).toFixed(2)) }
                      </Typography>
                    </Grid>
                  
                  </Grid>
                </Box>
                </Grid>
                <Grid item md={6} xs={12} lg={12}> 
                  <Box>
                  <Grid container>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography className={classes.boxText}>Original Appraised Value:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}> 
                      {   Math.sign(key?.originalAppraisedAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(key?.originalAppraisedAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(key?.originalAppraisedAmount).toFixed(2)) }
                     
                      </Typography>
                    </Grid>

                    <Grid item md={6} xs={12} lg={6}>
                      <Typography className={classes.boxText}>Monthly Premium:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6}>
                      <Typography  className={classes.boxTextValue}> 
                      {   Math.sign(key?.miMonthlyPremiumAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(key?.miMonthlyPremiumAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(key?.miMonthlyPremiumAmount).toFixed(2)) }
                     </Typography>
                    </Grid>
                  
                  </Grid>
                 
                </Box>
                </Grid>
                </Box>
              
              </AccordionDetails>
              </Accordion>
            </Box>
            )
          })}
        
            </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
     </Box>
    
     {!props.displayStatus && (privateLabelUrl.toLowerCase() === "bsi") && (props.coveredBanner.bannerInfo.filter((item) => ((item.bannerName.toUpperCase() === 'COVERED') || (item.bannerName.toUpperCase() === 'MATIC'))).filter((key) => (key.isDisplay == true))[0]?.isDisplay) && <CoveredTile data={props.coveredBanner}/> }

     {!props.displayStatus && (privateLabelUrl.toLowerCase() === "bsi") && (props.coveredBanner.bannerInfo.filter((item) => ((item.bannerName.toUpperCase() === 'COVERED-P2S'))).filter((key) => (key.isDisplay == true))[0]?.isDisplay) && <CoveredTileP2S data={props.coveredBanner}/> }

      <Box container="true" maxWidth='xl' className={classes.transactionHistoryRoot} >
          <Box className={classes.formContainer} ref={componentRef}>
          <Grid container >
            <Grid item xs={12} sm={12} md={12}>
              <Box>
              <Typography className={classes.transactionHeading}>Mortgage Insurance Transaction History</Typography> 
              <Typography className={classes.transactionDesc}>The table below shows amounts that we have remitted on your behalf during the past year.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={0}  >
                <Box className={classes.paymentDetailsBox}>
                   <TableContainer className={classes.table}>
                    <Table  aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Payee</StyledTableCell>
                          <StyledTableCell align="left">Type</StyledTableCell>
                          <StyledTableCell align="center">Date</StyledTableCell>
                          <StyledTableCell align="right">Amount</StyledTableCell>
                        </TableRow>
                      </TableHead>
                        <TableBody>
                            { escrowDetails?.length > 0 ? escrowDetails.slice(0,5).map((row, i) => (
                            <StyledTableRow key={i} >
                              <StyledTableCell align="left" >{row?.payee ? row?.payee : 'N/A'}</StyledTableCell>
                              <StyledTableCell align="left" >{row?.description ? row?.description : 'N/A'}</StyledTableCell>
                              <StyledTableCell align="center">{row?.effectiveDate ? moment(row.effectiveDate).format('ll') : 'N/A'} </StyledTableCell>
                            
                              <StyledTableCell align="right" style={{color: '#636363', fontSize: '14px', fontWeight: '600'}}>{ row?.escrowAmount ?  
                                Math.sign(row?.escrowAmount) == -1 ? '-$' + numberWithCommas(parseFloat(Math.abs(row?.escrowAmount)).toFixed(2))  : '$' + numberWithCommas(parseFloat(row?.escrowAmount).toFixed(2)) : 'N/A'
                              }</StyledTableCell>

                            </StyledTableRow>)) : <StyledTableRow>
                                <StyledTableCell  colSpan={4}>
                                  <p className={classes.noDataFoundText}>No record found</p>
                                </StyledTableCell>
                              </StyledTableRow>
                            }
                           
                        </TableBody>
                      </Table>
                      { escrowDetails?.length > 5 ? 
                       <Box><Typography className={classes.detailedTransaction}  style={{ color: '#0094FF', cursor: "pointer" }} onClick={() => handleViewMore()}>Show More</Typography></Box> : null}
                    </TableContainer>
                </Box>
              </Grid>
            </Grid>
           
          </Grid>
        
         </Box>
     </Box> 

    <Box container="true" maxWidth='xl' className={classes.faqRoot} >
          <Box className={classes.formContainer} ref={componentRef}>
              { data?.lstPageContentDetails?.length > 0 ?
                 <Faq  contentKey={'MortgageInsurance'}/> : null
              }
            </Box>
    </Box>
    </CssBaseline>
  )
}
