import React, {useState, useEffect} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { saveInsuranceActivity, getConsent } from '../../actions';
import { getUserType, getAppSource, isAdminCheck } from '../../helpers';
import { SAVE_TIME_POPUP_HEADING, BASIC_INFO_POPUP_SUB_HEADING, DISCLAIMER_COVERED, DISCLAIMER_MATIC } from '../../helpers/constants';
import Cookies from 'js-cookie';

export const CoveredBannerPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isGeneralConsentExpanded, setGeneralConsentExpanded] = useState(false);
  const [generalConsent, setGeneralConsent] = useState();
  const [loading, setLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const { buttonURL, coveredURL, bannerName } = props.data;
  
  const toggleGeneralConsent = () => {
    setGeneralConsentExpanded(!isGeneralConsentExpanded);
  };

  useEffect(() => {
    if (bannerName.toLowerCase() !== 'matic') {
      handleConsent()
    } else {
      setLoader(false)
    }
  }, []);

  const handleConsent = async () => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    const response = await getConsent(data);
    if (response && response?.responseData?.lstResponseStatus && (response?.responseData?.lstResponseStatus[0]?.statusCode == 0)) { 
      setGeneralConsent(response?.responseData?.responseData?.generalTerms)
      setLoader(false)
      setErrorMsg('')
    } else {
      setLoader(false)
      setErrorMsg(renderDefaultMsg('400'));
    }
  }

  const handleClick = (url, type) => {
    if ((type == 'manually') && (props.page == 'home')) {
      const manuallyUrl = `${url}${'?sd_amm=post_login_banner&utm_medium=post_login_banner&utm_content=PostLoginDashboard'}`
      window.open(manuallyUrl, '_blank')
    } else if ((type == 'manually') && (props.page !== 'home')) {
      const manuallyUrl = `${url}${'?sd_amm=post_login_banner&utm_medium=post_login_banner&utm_content=PostLoginEscrow'}`
      window.open(manuallyUrl, '_blank')
    } else if ((type == 'prefill') && (props.page == 'home')) {
      const manuallyUrl = `${url.split("#")[0]}${'?sd_amm=post_login_banner&utm_medium=post_login_banner&utm_content=PostLoginDashboard'}`
      window.open(manuallyUrl, '_blank')
    } else if ((type == 'prefill') && (props.page !== 'home')) {
      const manuallyUrl = `${url.split("#")[0]}${'?sd_amm=post_login_banner&utm_medium=post_login_banner&utm_content=PostLoginEscrow'}`
      window.open(manuallyUrl, '_blank')
    }
  
    props.handleCancelIcon()
    if ((type == 'manually') && (props.page == 'home')) {
       const remark = `${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on FILL MANUALLY button on popup for home page tile`
       handleActivity(remark, true)
    } else if ((type == 'manually') && (props.page !== 'home')) {
      const remark = `${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on FILL MANUALLY button on popup for taxes and insurance page tile`
      handleActivity(remark, true)
    } else if ((type == 'prefill') && (props.page == 'home')) {
      const remark = `${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on PROCEED button on popup for home page`
      handleActivity(remark, true)
   } else if ((type == 'prefill') && (props.page !== 'home')) {
     const remark = `${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on PROCEED button on popup for taxes and insurance page tile`
     handleActivity(remark, true)
   }
  }
  
  const handleActivity = async (activityRemark, btnClick) => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType": (bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered',
      "isGenConsent": ((bannerName.toLowerCase() == 'covered') && btnClick) ? true : false,
      "activityRemark": activityRemark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }

  const handleClose = () => {
    props.handleCancelIcon();
    handleActivity(props?.page == 'home' ? `${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on CROSS button on post signin home page tile` : `${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on CROSS button on post signin taxes and insurance page tile`, false)
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
        <IconButton className={classes.closeIcon} onClick={() => handleClose()}>
            <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{SAVE_TIME_POPUP_HEADING}</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Typography className={classes.paragraph}>{BASIC_INFO_POPUP_SUB_HEADING}</Typography>
             </Box>
            </Box> 
          </Box>
        </Box>

        {((bannerName.toLowerCase() == 'covered') && generalConsent && !loading) &&
      
        <Box>
          <Box className={classes.descriptionTitle}>General Consent:</Box>
          <Box className={classes.generalConsent}> {isGeneralConsentExpanded ? <div dangerouslySetInnerHTML={{ __html: generalConsent }}/>  :
           <div dangerouslySetInnerHTML={{ __html:  `${generalConsent?.substring(0, 290)}...` }}></div> }
           <span onClick={toggleGeneralConsent} className={classes.view}>{isGeneralConsentExpanded ? 'View Less' : 'View More'}</span>
          </Box>
         </Box> }

         {loading && <Box className={classes.loadingRoot}>
          <CircularProgress />
        </Box> }

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => handleClick(coveredURL, 'manually')}
              disabled={isAdminCheck()}
            > FILL MANUALLY</Button>
          </Box>

          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => handleClick(buttonURL, 'prefill')}
                disabled={isAdminCheck()}
              >PROCEED & PREFILL</Button>
          </Box> 
        </Box>
        <Typography className={classes.disclaimer}>({(bannerName.toLowerCase() == 'covered') ? DISCLAIMER_COVERED : DISCLAIMER_MATIC})</Typography> 
       
       
      </Box> 
    </Dialog>
  )
}
