import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 550,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  descriptionTitle: {
    color: '#4D4D4D',
    fontSize: 12,
    fontWeight: 700,
    padding: '4px 30px',
    marginTop: 10
  },

  phone: {
    color: '#4D4D4D',
    fontSize: 14,
    fontWeight: 500,
    padding: '4px 30px'
  },

  generalConsent: {
    color: '#4D4D4D',
    fontSize: 12,
    fontWeight: 400,
    padding: '4px 30px'
  },

  telephoneConsent: {
    color: '#4D4D4D',
    fontSize: 12,
    fontWeight: 400,
    padding: '2px 2px',
    marginTop: 10
  },

  telephoneConsentContainer: {
    display: 'flex',
    alignItems: 'start',
    padding: 8
  },

  view : {
    color: "#0071FF",
    fontFamily: "Montserrat",
    fontSize: 10,
    fontWeight: "700",
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  a: {
      color: "#0071FF",
      fontFamily: "Montserrat",
      fontSize: 10,
      fontWeight: "700",
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },
 
  
  btn: {
    width: 175,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    margin: "10px",
    padding: '10px 10px',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: '14px'
    }
  },

  cardFooter: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: "#fff",
    marginBottom: 30,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 80px 0px',
      width: '94%'
    },
  },

  closeIcon: {
    position: 'absolute',
    right: -5,
    paddingTop: 7,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    '&.Mui-checked': {
      color: '#000',
    },
    '& .MuiInputLabel-outlined': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.15px',
      lineHeight: '20px',
      background: '#fff'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#535D6D'
    },
    '& .Mui-focused.MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSelect-select': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.15px',
      lineHeight: '20px'
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    }
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 4,
    textAlign: 'center',
    paddingTop: 4
 },

 infoIcon: {
  paddingRight: "10px",
  fontSize: '30px',
  color: "#F59720"
},

 proceed: {
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  marginBottom: 0,
  marginTop: 22,
  textAlign: 'center',
},

cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 15
},

imageContainer: {
  padding: "6px",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down('sm')]: {
    display: "block"
  },
},

card1: {
  maxWidth: '100%',
  padding: '10px',
  borderRadius: 12,
  border: '1px solid #EDB36A',
  backgroundColor: "#FBF0E1",
  boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  marginBottom: 20,
  // display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    width: '100%',
    padding: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }
},

cardIcon: {
  width: 28,
  height: 22,
  color: "#EDB36A",
  marginTop: 22,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

disableBtn: {
  color: "rgba(0, 0, 0, 0.26)",
  boxShadow: "none",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  cursor: "pointer",
  width: 188,
  height: '32px',
  fontFamily: "Montserrat",
  fontSize: '1.5em',
  fontWeight: "600",
  margin: "10px",
  '&:hover': {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  [theme.breakpoints.down('sm')]: {
      width: 80,
      fontSize: '1.3em'
  }
},

disclaimer: {
  color: "#121D22",
  fontSize: 9,
  fontWeight: "400",
  fontFamily: "Montserrat",
  wordBreak: "break-word",
  paddingBottom: 22,
  paddingLeft: 25,
  paddingRight: 25
},

closeIco: {
  position: 'absolute',
  right: -5,
  paddingTop: 7,
  [theme.breakpoints.down('sm')]: {
      right: 10
  }
},

clickLink: {
  color: '#00000',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontStyle: 'normal',
  cursor:'pointer',
  fontWeight: '700',
  lineHeight: '16px',
  textDecorationLine: 'underline'
},

textField: {
  fontFamily: "Montserrat",
  fontSize: " 16px",
  fontWeight: '600'
},

inputContainer: {
  padding: '8px 30px 2px 30px'
},

labelStyle: {
  color: "#535D6D",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "600",
  [theme.breakpoints.down('sm')]: {
    fontSize: 11
  },
},

supportDocError: {
  color: 'red',
  fontFamily: "Montserrat",
  fontSize: 12,
  lineHeight: "22px",
  fontWeight: '500',
  padding: '0px 30px'
},

loadingRoot: {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
},

errorText: {
  color: "red",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: 'center',
  marginBottom: 20,
},

}));

