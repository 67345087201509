import { makeStyles } from '@material-ui/core/styles';
import coveredInsurance from '../../assets/coveredInsurance.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 10,
    marginBottom: 28,
    // backgroundColor: "#1875bf",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    position: 'relative'
  },

  bodyTitle: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '3em',
    fontWeight: "500",
    textAlign: 'center',
    marginTop: 31,
    maxWidth: 221,
  },

  title: {
    color: "#FFF",
    fontSize: 22,
    fontWeight: "700",
    fontFamily: "Montserrat",
    textAlign: 'center',
    maxWidth: '290px',
    wordBreak: "break-word",
    paddingTop: 22
  },

  disclaimer: {
    color: "#FFF",
    fontSize: 9,
    fontWeight: "400",
    fontFamily: "Montserrat",
    wordBreak: "break-word",
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 5
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bodyText: {
    maxWidth: '290px',
    color: "rgba(0,0,0,0.74)",
    fontWeight: '400',
    fontFamily: "Montserrat",
    fontSize: '16px',
    wordBreak: "break-word",
    '& p': {
      maxWidth: '290px',
      color: "rgba(0,0,0,0.74)",
      fontWeight: '400',
      fontFamily: "Montserrat",
      fontSize: '16px',
      wordBreak: "break-word",
    }
  },

  bodyTextContainer: {
    display: 'flex',
    marginBottom: 2,
    width: 284,
    position: 'relative'
  },

  footer: {
    padding: '0em 45px 0em'
  },

  logo: {
    width: '100%',
    
  },

  bodyText: {
    position: 'absolute',
    marginTop: '174px',
    padding: '20px'
  },

  btn: {
    top: '88%',
    position: 'absolute',
    width: '75%',
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "700",
    display: "block",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    borderRadius: "4px",
    padding: "8px",
    height: '38px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#19CF7C",
      textDecoration: "none",
    }
  },
 
  openIcon: {
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      right: "0px",
    },
  },

}));

