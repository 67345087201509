import React, { useEffect, useState, useRef  } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box } from '@material-ui/core';
import { useStyles } from './styles'
import  coveredBanner  from '../../../assets/coveredBanner.png';
import window from '../../../assets/Refinance/window.png';
import  coveredInsurance  from '../../../assets/coveredInsurance.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { saveInsuranceActivity } from '../../../actions'
import Cookies from 'js-cookie';
import { getUserType, getAppSource } from '../../../helpers';
import { CoveredBannerPopup } from '../../CoveredBannerPopup';

export const CoveredTile = (props) => {
  
  const theme = useTheme();
  const classes = useStyles();
  const componentRef = useRef();
 
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const [closePopup, setClosePopup] = useState(false);
  const [bannerInfo, setBannerInfo] = useState();

  useEffect(() => {
    const filterBanner = props.data.bannerInfo.filter((item) => ((item.bannerName.toUpperCase() === 'COVERED') || (item.bannerName.toUpperCase() === 'MATIC'))).filter((key) => (key.isDisplay == true))[0];
    setBannerInfo(filterBanner)
  }, [props.data])
  
  const handleCancelIcon = () => {
    setClosePopup(false)
  }

  const handleActivity = async (remark, status) => {
    if (status) {
      setClosePopup(true)
    }

    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType": (bannerInfo.bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered',
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }

  return (
    <CssBaseline>
     
     { (!mobileMediaQuery) && bannerInfo &&
     <Box container="true" maxWidth='xl' className={classes.transactionHistoryRoot} >
        <Box className={classes.coveredContainer} ref={componentRef}>
          <Box className={classes.body}>
             <img className={classes.logo} src={bannerInfo?.taxBannerURL} alt='desktop banner' />
             <div className={classes.taxBannerBody} dangerouslySetInnerHTML={{ __html: bannerInfo?.taxBannerBody }}></div>
            <Box >
              <a className={(bannerInfo.bannerName.toUpperCase() == 'COVERED') ? classes.quickQuoteBtn : classes.matic} onClick={() => handleActivity(`${(bannerInfo.bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on quote button on post signin taxes and insurance page tile`, true)}>{bannerInfo?.buttonText.split("#")[0]}<img src={window} className={classes.openIcon}/></a> 

              <Box className={(bannerInfo.bannerName.toLowerCase() == 'covered') ? classes.callOptionCovered : classes.callOptionMatic}>Call { (bannerInfo.bannerName.toLowerCase() == 'covered') ? 'Covered' : 'Matic'} at : <img src="https://mlw-uat.bsifinancial.com/images/icon/phone.svg" style={{paddingLeft: '8px', paddingRight: '8px'}}/><a onClick={() => handleActivity(`${(bannerInfo.bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on call option on post signin taxes and insurance page tile`, false)} href={"tel:" + bannerInfo.buttonText.split('#')[1]} style={{color: '#F59720', fontSize: '18px', fontWeight:700 }}>{bannerInfo.buttonText.split('#')[1]}</a>
              </Box>
            </Box>
          </Box>
       </Box>
      </Box>
    }

    { (mediaQuery && mobileMediaQuery) && bannerInfo &&
      <Box className={classes.coveredBannerRootCont}>
        <Box className={classes.bodyContainer} >         
          <img className={classes.logo} src={bannerInfo?.bannerImageUrl} alt='mobile image' />
          <div className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerInfo?.bannerBody }}></div>
        </Box>

        <Box className={classes.footer}>
          <Box className={(bannerInfo.bannerName.toLowerCase() == 'covered') ? classes.callOptionMaticMobile : classes.callOptionMaticMobile}>Call { (bannerInfo.bannerName.toLowerCase() == 'covered') ? 'Covered' : 'Matic'} at : <img src="https://mlw-uat.bsifinancial.com/images/icon/phone.svg" style={{paddingLeft: '8px', paddingRight: '8px'}}/><a onClick={() => handleActivity(`${(bannerInfo.bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on call option on post signin taxes and insurance page tile`, false)} href={"tel:" + bannerInfo.buttonText.split('#')[1]} style={{color: '#F59720', fontSize: '18px', fontWeight:700 }}>{bannerInfo.buttonText.split('#')[1]}</a>
          </Box>
          <a className={classes.btnInsurance} onClick={() => handleActivity(`${(bannerInfo.bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on quote button on post signin taxes and insurance page tile`, true)}>{bannerInfo?.buttonText.split("#")[0]} <img src={window} className={classes.openIcon}/></a>
        </Box>
      </Box>
   }
  
   { closePopup &&
      <CoveredBannerPopup open={closePopup} handleCancelIcon={handleCancelIcon} data={bannerInfo} page="taxes"/>
     }
    </CssBaseline>
  )
}
