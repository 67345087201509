import React, {useState, useEffect} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography, Checkbox, FormControl, InputLabel, OutlinedInput, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { saveInsuranceActivity, getConsent } from '../../actions';
import { getUserType, getAppSource, isAdminCheck, checkDigitOnly, mobileNoStartsWith, renderDefaultMsg  } from '../../helpers';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../helpers/encrypt';

export const CoveredP2SBannerPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isGeneralConsentExpanded, setGeneralConsentExpanded] = useState(false);
  const [isTcpaExpanded, setTcpaExpanded] = useState(false);

  const [description, setDescription] = useState();
  const [generalConsent, setGeneralConsent] = useState();
  const [checkbox, setCheckboxStatus] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [consentInfo, setConsentInfo] = useState();
  const [mobileNumberErr, setMobileNumberErr] = useState(false);
  const [loading, setLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const { buttonURL, coveredURL, bannerName } = props.data;
  
  const toggleGeneralConsent = () => {
    setGeneralConsentExpanded(!isGeneralConsentExpanded);
  };

  const toggleTcpaConsent = () => {
    setTcpaExpanded(!isTcpaExpanded);
  };

  useEffect(() => {
    handleConsent()
  }, []);

  const handleConsent = async () => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    const response = await getConsent(data);
    if (response && response?.responseData?.lstResponseStatus && (response?.responseData?.lstResponseStatus[0]?.statusCode == 0)) { 
      setGeneralConsent(response?.responseData?.responseData?.generalTerms)
      setDescription(response?.responseData?.responseData?.tcpaTerms)
      setMobileNumber(decrypt(response?.responseData?.responseData?.mobileNumber))
      setConsentInfo(response?.responseData?.responseData)
      setLoader(false)
      setErrorMsg('')
    } else {
      setLoader(false)
      setErrorMsg(renderDefaultMsg('400'));
    }
  }

  const handleClick = (url, type) => {
    if ((mobileNumber.length !== 10) && checkbox) {
      setMobileNumberErr(true)
    } else {
      props.handleCancelIcon();
      window.open(url, '_blank')
      if ((type == 'prefill') && (props.page == 'home')) {
          const remark = 'Covered - User clicked on PROCEED button on popup for home page'
          handleActivity(remark, true)
      } else if ((type == 'prefill') && (props.page !== 'home')) {
        const remark = 'Covered - User clicked on PROCEED button on popup for taxes and insurance page tile'
        handleActivity(remark, true)
      }
    }
  }
  
  const handleActivity = async (activityRemark, btnStatus) => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType": 'Covered-P2S',
      "activityRemark": activityRemark,
      "isGenConsent": btnStatus ? true : false,
      "genConsentId": consentInfo.generalID,
      "genConsentTerms": consentInfo.generalTerms,
      "isTCPAConsent": btnStatus ? checkbox : false,
      "tcpaConsentId":  consentInfo.tcpaid,
      "tcpaConsentTerms":  consentInfo.tcpaTerms,
      "mobileNumber": encrypt(mobileNumber),
      "leadID": consentInfo.leadID,
      "emailID": consentInfo.emailID,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }
 
  const handleClose = () => {
    props.handleCancelIcon();
    handleActivity(props?.page == 'home' ? 'Covered - User clicked on CROSS button on post signin home page tile' : 'Covered - User clicked on CROSS button on post signin taxes and insurance page tile', false)
  }

  const phoneChangeHandler = (prop) => (event) => {
    // setMobileNumberErr(false)
    if (prop === 'phoneNumber') {
      if (checkDigitOnly(event.target.value) && (event.target.value.length <= 10)) {
        if (mobileNoStartsWith(event.target.value)) {
          setMobileNumberErr(false)
          setMobileNumber(event.target.value)
        } else {
          setMobileNumberErr(true)
          setMobileNumber(event.target.value)
        }
      } 
      // else {
      //   setMobileNumberErr(true)
      //   setMobileNumber(event.target.value)
      // }
    }
  }
  
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>

     <Box className={classes.root}>
        <IconButton className={classes.closeIcon} onClick={() => handleClose()}>
            <CancelIcon />
        </IconButton>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Consent Confirmation</Typography>
        </Box>
        {errorMsg !== '' ? <Typography className={classes.errorText}>{errorMsg}</Typography> : null}

    {consentInfo && !loading &&
      <>
       <Box>
          <Box className={classes.descriptionTitle}>General Consent:</Box>
          <Box className={classes.generalConsent}> {isGeneralConsentExpanded ? <div dangerouslySetInnerHTML={{ __html: generalConsent }}/>  :
           <div dangerouslySetInnerHTML={{ __html:  `${generalConsent?.substring(0, 290)}...` }}></div> }
           <span onClick={toggleGeneralConsent} className={classes.view}>{isGeneralConsentExpanded ? 'View Less' : 'View More'}</span>
          </Box>

          <Box className={classes.descriptionTitle}>Telephone Consent <span style={{color: '#B4B4B4'}}>(Optional):</span></Box>
          {checkbox && <Box className={classes.generalConsent}>
              <Box className={classes.phone}>Please enter your phone number:</Box>
                <Box className={classes.inputContainer}>
                  <FormControl variant="outlined" fullWidth >
                  <InputLabel className={classes.labelStyle} htmlFor="outlined-phone">Phone Number*</InputLabel>
                    <OutlinedInput
                      color="primary"
                      id="outlined-phone"
                      type='text'
                      value={mobileNumber}
                      labelWidth={140}
                      name="phoneNumber"
                      onChange={phoneChangeHandler('phoneNumber')}
                      inputProps={{
                        className: classes.inputStyle,
                        maxLength: 10,
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelStyle
                        }
                      }}
                    />
                  </FormControl>
              </Box>
              {mobileNumberErr && <p className={classes.supportDocError}>Please enter a valid phone number.</p>}
            </Box> }
          <Box className={classes.telephoneConsentContainer}>
              <Checkbox
                  checked={checkbox}
                  onChange={(event) => setCheckboxStatus(event.target.checked)}
                  className={classes.checkboxContainer}
                  name="agree"
                  style={{paddingLeft: "24px"}}
              />
           { description && <Box className={classes.telephoneConsent}>  {isTcpaExpanded ? <div dangerouslySetInnerHTML={{ __html: description?.replaceAll('[NUMBER]', decrypt( consentInfo?.mobileNumber)) }}/>  :
            <div dangerouslySetInnerHTML={{ __html:  `${description?.substring(0, 180)}...` }}></div> }
            <span onClick={toggleTcpaConsent} className={classes.view}>{isTcpaExpanded ? 'View Less' : 'View More'}</span>
            </Box> }
           </Box> 
          
         </Box> 

        <Box className={classes.cardFooter}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => handleClick(buttonURL, 'prefill')}
                disabled={isAdminCheck() || (mobileNumberErr && checkbox)}
              >PROCEED</Button>
         </Box>
         </> }

      </Box> 
     {loading && <Box className={classes.loadingRoot}>
        <CircularProgress />
      </Box> }
    </Dialog>
  )
}
