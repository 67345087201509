import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import './styles.css';
import { useStyles } from './styles';
import rightArrow from '../../assets/LandingPage/arrow.svg';
import loanService from '../../assets/LandingPage/loanService.svg';
import didUknow from '../../assets/LandingPage/didUknow.svg';
import whoWeAre from '../../assets/LandingPage/whoWeAre.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent, getLandingPageTiles, getLandingBanners, saveInsuranceActivity } from '../../actions';
import { isHEI, getUserType, getAppSource } from '../../helpers';

import { useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import hero_image from '../../assets/LandingPage/hero_mobile.png';
import landingBgImg from '../../assets/LandingPage/landingBgImg.jpg'
import openIcon from '../../assets/openInNew.png';
import coveredCall from '../../assets/coveredCall.svg';
import maticCall from '../../assets/maticCall.svg';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const Home = () => {

  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const mytheme = useSelector(state => state.theme);
  const { headerBackgrounColorCode, landingBgImgURL } = mytheme;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tiles, setTiles] = useState([]);
  const history = useHistory();
  const content = useSelector(state => state.pageContent.landing);
  const matches = useMediaQuery('(max-width:600px)');
  const [bannerImages, setBanners] = useState([]);

  const SlickNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={classes.nextBtn} onClick={onClick} >
        <NavigateNextIcon className={classes.nextArrow} />
      </div>
    );
  }

  const SlickPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={classes.prevBtn} onClick={onClick}>
        <NavigateBeforeIcon className={classes.prevArrow} />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: !matches && <SlickNextArrow />,
    prevArrow: !matches && <SlickPrevArrow />,
    pauseOnHover: false,
    autoplaySpeed: 10000,
  };

  useEffect(() => {
    getTilesData();
    getContentData()
    if (!isHEI()) {
      getBannersData()
    }
  }, []);
 
  const getBannersData = async () => {
    const res = await getLandingBanners();

    if (res && res.responseData.responseData !== 0) {
      if (privateLabelUrl.toUpperCase() === "BSI") {
        const data = res.responseData.responseData.filter((banner) => banner.bannercode !== "where your loan lives")
        setBanners(data)
      } else {
        setBanners(res.responseData.responseData)
      }
    }
  }

  const getTilesData = async () => {
    const res = await getLandingPageTiles();
    if (res && res.length !== 0) {
      const newRes = res.sort((a, b) => a.dispayOrder - b.dispayOrder);
      setTiles(newRes)
    }
  }

  const getContentData = () => {
    dispatch(getPageContent('PC001'));
  }

  const handleNav = (card) => {
    const { tileURL } = card;
    if (tileURL && tileURL.includes("http")) {
      const win = window.open(tileURL, "_blank");
      win.focus();
    } else {
      history.push(`/${tileURL}`)
    }
  }
 
  const handleBannerClick = (banner) => {
    // if (item.buttonText.toLowerCase().includes("inquire")) {
      const win = window.open(banner.buttonUrl, "_blank");
      win.focus();
      if ((banner.bannercode.toLowerCase() == 'matic') || (banner.bannercode.toLowerCase() == 'covered')) {
        handleActivity(banner, true)
      }
    // } else {
    //   history.push(`/${item.buttonUrl}`)
    // }
  }

  const refactorContent = (key) => {
    if (content && content.lstPageContentDetails) {
      return content.lstPageContentDetails.filter(item => item.contentKey.toUpperCase() === key.toUpperCase())[0]
    }
    return null;
  }

  const handleActivity = async (banner, status) => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType": (banner.bannercode.toLowerCase() == 'matic') ? 'matic' : 'covered',
      "activityRemark": status ? `${(banner.bannercode.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on quote button on pre-signin banner` : `${(banner.bannercode.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on call option on post signin home page tile`,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }

  const hero = refactorContent('Header');
  const loanServices = refactorContent('Banner1');
  const whoWeAreSection = refactorContent('Banner2');
  const covid = refactorContent('Banner3');
  const didUKnow = refactorContent('Banner4');

  return (
    <Box className='root'>
      {bannerImages.length == 0 && (!isHEI()) ? <Box className='loaderRoot'><CircularProgress /></Box> : null}
      
      {hero && (bannerImages.length > 0) && (!isHEI()) &&
        <Box className='heroSection'>
           <Slider {...settings}>
            {bannerImages?.map((item) => (
                 <Box className='bannerWrapper acc1'>
                <>
                  <img src={matches ? item.imagePathMobile : item.imagePath} style={{width: '100%'}}/>
                  <Box className='bannerContentContainer'>
                    <div dangerouslySetInnerHTML={{ __html: item.headerText }} className='headerText'/>
                    <div dangerouslySetInnerHTML={{ __html: matches ? item.subHeaderText : item.contentText }} className='contentText' />
                    
                    { ((item.bannercode.toLowerCase() == 'matic') || (item.bannercode.toLowerCase() == 'covered')) ?
                    <div className={matches ? classes.mobileCallContainer : classes.desktopCallContainer}>
                      <Button color='primary' variant='contained' onClick={() => handleBannerClick(item)} className='buttonText'>{item.buttonText.split("#")[0]}                    
                        <img src={openIcon} className={classes.openIcon}/>
                      </Button>
                    <div className={matches ? classes.mobileOr : classes.desktopOr}>OR</div>
                    <a onClick={() => handleActivity(item, false)} href={"tel:" + item.buttonText.split('#')[1]} style={{color: '#F59720', fontSize: '18px', fontWeight:700 }}>
                      <div style={{position: 'relative', display: 'inline-block'}}>
                      <img src={(item.bannercode.toLowerCase() == 'matic') ? maticCall : coveredCall} style={{width: '288px', height: '80px'}}/> 
                      <div className={classes.call}>
                         {item.buttonText.split("#")[1]}
                      </div>
                      </div></a>
                    </div> :
                     <Button color='primary' variant='contained' onClick={() => handleBannerClick(item)} className='buttonText'>{item.buttonText.split("#")[0]}                    
                     <img src={openIcon} className={classes.openIcon}/>
                   </Button>
                     }
 
                  </Box>
                  </>
                  </Box>
              ))}
           </Slider>
           <Box className="heroFooter">
            <Box className='cardContainer'>
              {tiles.map((item) => {
                return (
                  <Box key={item.id} id='card' className={classes.tile} onClick={() => handleNav(item)}>
                    <img className='cardImg' src={item.tileImageUrl} alt="icon" />
                    <p className='cardTitle'>{item.tileTitle}</p>
                    <img src={rightArrow} className='arrowIcon' alt='icon' />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      }

      {hero && (isHEI()) &&
        <Box className='heroSection'>
          <Box className='wrapper'
            style={{
              backgroundImage: matches ? `url(${hero_image})` : `url(${landingBgImgURL})`,
            }}>
            <Box className='contentContainer'>
              <h1 className='heroTitle'>{hero.contentHeader}</h1>
              <p className='heroSubTitle'>{hero.contentSubHeader}</p>
              <Button color='primary' variant='contained' component={Link} to='/AccountVerification' className='heroBtn'>Create your account</Button>
            </Box>
          </Box>
          <Box className="heroFooter">
            <Box className='cardContainer'>
              {tiles.map((item) => {
                return (
                  <Box key={item.id} id='card' className={classes.tile} onClick={() => handleNav(item)}>
                    <img className='cardImg' src={item.tileImageUrl} alt="icon" />
                    <p className='cardTitle'>{item.tileTitle}</p>
                    <img src={rightArrow} className='arrowIcon' alt='icon' />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>}

      {loanServices &&
        <Box className='loanSection'>
          <Box className='loanContainer'>
            <Box className='imgContainer'>
              <img className='img' src={mytheme.landingB1ImgURL} alt='img'/>
            </Box>
            <Box className='content'>
              <h2 className='title'>{loanServices.contentHeader}</h2>
              <p className='subTitle'>{loanServices.contentSubHeader}</p>
              <p className='text' dangerouslySetInnerHTML={{ __html: loanServices.contentValue }}></p>
            </Box>
          </Box>
        </Box>}
      {whoWeAreSection &&
        <Box className={clsx(classes.whoWeAre, 'whoWeAreSection')}>
          <Box className='whoWeAreContainer'>
            <Box className='whoWeAreImgContainerMobile'>
              {/* <img className='img' src={whoWeAre} alt='img' /> */}
              <img className='img' src={mytheme.landingB2ImgURL} alt='img'/>
            </Box>
            <Box className='whoWeAreContent'>
              <h2 className='title'>{whoWeAreSection.contentHeader}</h2>
              <p className='subTitle'>{whoWeAreSection.contentSubHeader}</p>
              <p className='text' dangerouslySetInnerHTML={{ __html: whoWeAreSection.contentValue }}></p>
              <Button component={Link} to='/AboutUs' className={classes.btn}>View More</Button>
            </Box>
            <Box className='whoWeAreImgContainer'>
              {/* <img className='img' src={whoWeAre} alt='img' /> */}
              <img className='img' src={mytheme.landingB2ImgURL} alt='img'/>
            </Box>
          </Box>
        </Box>}
      {covid &&
        <Box className='covidSection'>
          <Box className='covidContainer'>
            <h2 className='covidTitle'>{covid.contentHeader}</h2>
            {covid.contentSubHeader ? <p className='subTitle'>{covid.contentSubHeader}</p> : null}
            <p className='covidSubTitle' dangerouslySetInnerHTML={{ __html: covid.contentValue }}></p>
          </Box>
        </Box>}
      {didUKnow &&
        <Box className='didUKnowSection' style={{ backgroundColor: privateLabelUrl.toUpperCase() === "BSI" ? "#FFD099" : '#FFD099' }}>
          <Box className='didUKnowContainer'>
            <Box className='didUKnowImgContainer'>
              {/* <img className='img' src={didUknow} alt='img' /> */}
              <img className='img' src={mytheme.landingB4ImgURL} alt='img'/>
            </Box>
            <Box className='content'>
              <h2 className='title'>{didUKnow.contentHeader}</h2>
              <p className='subTitle'>{didUKnow.contentSubHeader}</p>
              <p className='text' dangerouslySetInnerHTML={{ __html: didUKnow.contentValue }} />
              <Button component={Link} to='/Whatsnew' className={classes.btn}>Read more</Button>
            </Box>
          </Box>
        </Box>}
    </Box>
  )
}
