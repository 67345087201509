import { axiosProtected,axiosPublic } from '../config';

export const getMyProfile = async (data) => {
  try {
    const res = await axiosProtected.post('/MyProfile/GetMyProfile', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const confirmContactDetails = async (data) => {
  try {
    const res = await axiosProtected.post('/User/InsertContactDetailsAcknowledgement', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const updateMyProfile = async (data) => {
  try {
    const res = await axiosProtected.post('/MyProfile/UpdateMyProfile', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const getCommPreference = async (data) => {  
  try {
    const res = await axiosProtected.post('/MyProfile/GetCommPreference', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const updateCommPreference = async (data) => {
  try {
    const res = await axiosProtected.post('/MyProfile/UpdateCommPreference', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const updatePassword = async (data) => {
  try {
    const res = await axiosProtected.post('/MyProfile/ChangePassword', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const validateOTPAuth = async (data) => {
  try {
    const otp = await axiosProtected.post('/OT/ValidateOTP', data);
    if (otp && otp.data && (otp.data.lstResponseStatus[0].statusCode === '0' || otp.data.lstResponseStatus[0].statusCode === '1001')) {
      return otp.data
    }
    return null
  } catch (e) {    
    return null
  }
}

export const resendCodeAuth = async (data) => {
  try {
    const otp = await axiosProtected.post('/OT/SendOTP', data);
    return otp.data
  } catch (e) {    
    return null
  }
}

export const sendVerificationEmail = async (data) => {  
  try {
    const res = await axiosProtected.post('/MyProfile/SendVerificationEmail', data);
    return res.data
  } catch (e) {    
    return null
  }
}

export const validateVerifyEmailURL = async (data) => {  
  try {
    const res = await axiosPublic.post('/OT/ValidateVerifyEmailURL', data);     
    return res.data
  } catch (e) {
    return null
  }
}