import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '4.6em 1.6em 5.6em',
    backgroundColor: theme.footer.backgroundColor
  },

  rootWrapper: {
    width: '100%',
    maxWidth: '120em',
    margin: '0 auto',
  },

  mainContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // paddingBottom: 40,
    marginBottom: 20,
    width: '60%',
    // borderBottom: 'solid 1px #25ABC7',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%'
    },
  },

  textStyle: {
    color: theme.footer.fontColor,
    fontFamily: "Montserrat",
    fontSize: '1.4em'
  },

  phoneText: {
    marginTop: 40
  },

  linkContainer: {
    marginRight: 6,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      width: '40%',
    },
  },

  linkStyle: {
    color: theme.footer.titleColor,
    cursor: 'pointer',
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    display: 'block',
    textDecoration: 'none',
    paddingBottom: 6,
    maxWidth: '184px'
  },

  linkHeading: {
    fontFamily: "Montserrat",
    fontSize: "2em",
    fontWeight: "bold",
    paddingBottom: 10,
    color: theme.footer.fontColor,
  },

  copyRightText: {
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    color: theme.footer.fontColor,
  },

  footerNav: {
    display: 'flex',
    marginBottom: 20,
  },

  navLink: {

    fontFamily: "Montserrat",
    fontSize: '1.4em',
    textDecoration: 'none',
    padding: '0 10px',
    borderRight: 'solid 1px #fff',
  },

  footerFoot: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderTop: 'solid 1px #25ABC7',
    paddingTop: '12px'
  },

  description: {
    width: '100%',
    paddingTop: '12px',
    fontFamily: "Montserrat",
    paddingBottom: '12px',
    color: theme.footer.fontColor,
    fontSize: 12
  },

  appLinkContainer: {
    width: 300,
    marginRight: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
    },
  },

  imageLinkContainer: {
    width: '100%',
    display: "flex",
    justifyContent: 'space-between',
  },

  appLink: {
    display: 'block',
    marginRight: 20,
    color: theme.footer.fontColor,
  },

  footerLogo: {
    display: 'block',
    widh: 227,
    height: 50,
    marginBottom: 40,
  },

  addressContainer: {
    maxWidth: '100%',
    marginBottom: 20,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%'
    },
  },

  flexBox: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  image: {
    backgroundColor: "#FFFFFF",
    width: '40px',
    height: '40px',
    cursor: 'pointer'
  },
  imageFeedback: {
    // backgroundColor: "#FFFFFF",
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    paddingTop: 12,
    filter: 'contrast(2)'
  },
  feedbackBox: {
    borderLeft: '1px solid black',
    borderRadius: '4px 4px 0px 0px',
    cursor: 'pointer',
    width: '141px',
    background: '#7EB1CC',
    // borderRadius: 4,
    position: 'fixed',
    bottom: 0,
    right: 107,
    zIndex: 9000,
    height: 38,
    textAlign: 'center',
    // "width": "100%",
    // "height": "50px",
    "background": "#7EB1CC",
    "padding": "0",
    "display": "flex !important",
    "alignItems": "center !important"
  },

  textFeedback: {
    paddingLeft: '10px',
    "fontFamily": "Montserrat",
    "fontSize": "16px",
    "fontWeight": "600",
    paddingTop: 12,
    "color": "#fff",
    // "width": "62%",
    // "textAlign": "left"
  },
  feedbackBoxTop: {
    cursor: 'pointer',
    width: '141px',
    background: '#7EB1CC',
    // borderRadius: 4,
    position: 'fixed',
    bottom: 88,
    right: 107,
    zIndex: 9000,
    height: 38,
    textAlign: 'center',
    // "width": "100%",
    // "height": "50px",
    "background": "#7EB1CC",
    "padding": "0",
    "display": "flex !important",
    "alignItems": "center !important"
  },

  inputFeedback: {
    width: 441,
    background: '#7EB1CC',
    position: 'fixed',
    bottom: 37,
    right: 107,
    zIndex: 9000,
    height: 149,
    textAlign: 'center',
    "background": "white",
    "padding": "0",
    "display": "flex !important",
    "alignItems": "center !important"
  },

  helpAddress: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: '#FFFFFF',
    letterSpacing: '0.44px',
    lineHeight: '18px'
  },

  visitTimeFont: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.44px',
    fontFamily: 'Montserrat',
    color: '#FFFFFF',
    lineHeight: '18px',
  },

  flex: {
    display: 'flex',
    alignItems: 'unset'
  },

  contactTime: {
    color: '#FFFFFF'
  },

  noUnderLine: {
    textDecoration: 'none'
  },

  iconStyle: {
    height: 14, width: 16, color: '#FFFFFF', marginRight: '6px', marginLeft: '6px'
  },
}));