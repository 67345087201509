import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box } from '@material-ui/core';
import { useStyles } from './styles'
import { useTheme } from '@material-ui/core/styles';
import { saveInsuranceActivity } from '../../../actions'
import { getUserType, getAppSource } from '../../../helpers';
import { CoveredP2SBannerPopup } from '../../CoveredP2SBannerPopup';
import { useSelector } from 'react-redux';
import window from '../../../assets/Refinance/window.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import state from '../../../helpers/states.json';
import Cookies from 'js-cookie';

export const CoveredTileP2S = (props) => {
  
  const theme = useTheme();
  const classes = useStyles();
  const componentRef = useRef();
 
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const selectedAccountData = useSelector(state => state.selectedAccountData);

  const [closePopup, setClosePopup] = useState(false);
  const [bannerInfo, setBannerInfo] = useState();

  useEffect(() => {
    const filterBanner = props.data.bannerInfo.filter((item) => (item.bannerName.toUpperCase() === 'COVERED-P2S')).filter((key) => (key.isDisplay == true))[0];
    setBannerInfo(filterBanner)
  }, [props.data])
  
  const handleCancelIcon = () => {
    setClosePopup(false)
  }

  const handleActivity = async (remark, status) => {
    if (status) {
      setClosePopup(true)
    }

    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType":  'Covered-P2S',
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }

  return (
    <CssBaseline>
     
     { (!mobileMediaQuery) && bannerInfo &&
     <Box container="true" maxWidth='xl' className={classes.transactionHistoryRoot} >
        <Box className={classes.coveredContainer} ref={componentRef}>
          <Box className={classes.body}>
             <img className={classes.logo} src={bannerInfo?.taxBannerURL} alt='desktop banner' />
            <Box >
              <Box className={classes.state}>{state[selectedAccountData.propertyState]}</Box>
              <a className={classes.quickQuoteBtn} onClick={() => handleActivity('Covered - User clicked on quote button on post signin taxes and insurance page tile', true)}>{bannerInfo?.buttonText.split("#")[0]}<img src={window} className={classes.openIcon}/></a> 

              <Box className={classes.callOptionCovered}><a onClick={() => handleActivity('Covered - User clicked on call option on post signin taxes and insurance page tile', false)} href={"tel:" + bannerInfo.buttonText.split('#')[1]} className={classes.btnLabel}>{bannerInfo.buttonText.split('#')[1]}</a>
              </Box>
            </Box>
          </Box>
       </Box>
      </Box>
    }

    { (mediaQuery && mobileMediaQuery) && bannerInfo &&
      <Box className={classes.coveredBannerRootCont}>
        <Box className={classes.bodyContainer} >         
          <img className={classes.logo} src={bannerInfo?.bannerImageUrl} alt='mobile image' />
          <Box className={classes.stateMobile}>{state[selectedAccountData.propertyState]}</Box>
        </Box>

        <Box className={classes.footer}>
          <Box className={classes.callOptionMaticMobile}><a onClick={() => handleActivity('Covered - User clicked on call option on post signin taxes and insurance page tile', false)} href={"tel:" + bannerInfo.buttonText.split('#')[1]} className={classes.callMobile}>{bannerInfo.buttonText.split('#')[1]}</a>
          </Box>
          <a className={classes.btnInsurance} onClick={() => handleActivity('Covered - User clicked on quote button on post signin taxes and insurance page tile', true)}>{bannerInfo?.buttonText.split("#")[0]} <img src={window} className={classes.openIcon}/></a>
        </Box>
      </Box>
   }
  
   { closePopup &&
      <CoveredP2SBannerPopup open={closePopup} handleCancelIcon={handleCancelIcon} data={bannerInfo} page="taxes"/>
     }
    </CssBaseline>
  )
}
