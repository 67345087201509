import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { getUserType, getAppSource } from '../../helpers';
import { CoveredBannerPopup } from '../CoveredBannerPopup';
import { saveInsuranceActivity } from '../../actions';
import window from '../../assets/Refinance/window.png';
import Cookies from 'js-cookie';

export const CoveredBanner = (props) => {

  const { buttonColor, buttonText, buttonURL, bannerImageUrl, bannerName, bannerBody, bannerSubTitle } = props.banner;
  const [closePopup, setClosePopup] = useState(false);
  const classes = useStyles();
 
  const handleCancelIcon = () => {
    setClosePopup(false)
  }

  const handleActivity = async (remark, status) => {
    if (status) {
      setClosePopup(true)
    }

    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType": (bannerName.toLowerCase() == 'matic') ? 'matic' : 'covered',
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.body}>
          <img className={classes.logo} src={bannerImageUrl} alt='banner logo' />
          <Box className={classes.bodyText} dangerouslySetInnerHTML={{ __html: bannerBody }}></Box>
          <Box style={{color:'#4D4D4D',fontSize: '17px', fontWeight: 400, display: 'flex', position: 'absolute', top: '81%'}}>Call { (bannerName.toLowerCase() == 'covered') ? 'Covered' : 'Matic'} at : <img src="https://mlw-uat.bsifinancial.com/images/icon/phone.svg" style={{paddingLeft: '8px', paddingRight: '8px'}}/><a onClick={() => handleActivity(`${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on call option on post signin home page tile`, false)} href={"tel:" + buttonText.split('#')[1]} style={{color: '#F59720', fontSize: '18px', fontWeight:700 }}>{buttonText.split('#')[1]}</a>
          </Box>
        </Box> 
        
        <Box className={classes.footer}>
          {buttonURL && buttonURL.length ? <a className={classes.btn} style={{ backgroundColor: buttonColor }} onClick={() => handleActivity(`${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on quote button on post signin home page tile`, true)}>{buttonText.split("#")[0]} <img src={window} className={classes.openIcon}/></a> : null}
        </Box>

      </Box>
     { closePopup &&
      <CoveredBannerPopup open={closePopup} handleCancelIcon={handleCancelIcon} data={props.banner} page="home"/>
     }
     </>
  )
}
