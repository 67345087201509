import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { getUserType, getAppSource } from '../../helpers';
import { CoveredP2SBannerPopup } from '../CoveredP2SBannerPopup';
import { saveInsuranceActivity } from '../../actions';
import { useSelector } from 'react-redux';
import window from '../../assets/Refinance/window.png';
import state from '../../helpers/states.json';
import Cookies from 'js-cookie';

export const CoveredP2SBanner = (props) => {

  const { buttonColor, buttonText, buttonURL, bannerImageUrl, bannerName } = props.banner;
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const [closePopup, setClosePopup] = useState(false);
  const classes = useStyles();

  const handleCancelIcon = () => {
    setClosePopup(false)
  }

  const handleActivity = async (remark, status) => {
    if (status) {
      setClosePopup(true)
    }

    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "insuranceType": 'Covered-P2S',
      "activityRemark": remark,  
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await saveInsuranceActivity(data);
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.body}>
          <img className={classes.logo} src={bannerImageUrl} alt='covered tile' />
          <Box className={classes.state}>{state[selectedAccountData.propertyState]}</Box>
          <Box style={{color:'#4D4D4D',fontSize: '17px', fontWeight: 400, display: 'flex', position: 'absolute', top: '81%'}}><a onClick={() => handleActivity(`${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on call option on post signin home page tile`, false)} href={"tel:" + buttonText.split('#')[1]} className={classes.call}>{buttonText.split('#')[1]}</a>
          </Box>
        </Box> 
        
        <Box className={classes.footer}>
          {buttonURL && buttonURL.length ? <a className={classes.btn} style={{ backgroundColor: buttonColor }} onClick={() => handleActivity(`${(bannerName.toLowerCase() == 'matic') ? 'Matic' : 'Covered'} - User clicked on quote button on post signin home page tile`, true)}>{buttonText.split("#")[0]} <img src={window} className={classes.openIcon}/></a> : null}
        </Box>

      </Box>
      { closePopup &&
        <CoveredP2SBannerPopup open={closePopup} handleCancelIcon={handleCancelIcon} data={props.banner} page="home"/>
      }
     </>
  )
}
